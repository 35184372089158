import { catApi, catVue } from '@/util/logging';

import { BaseType } from "./util/BaseType";
import { Expose } from "class-transformer";
import { api } from '@/util/api';

export class VCAImportFehlerModel extends BaseType {

    @Expose() uid = '';
    @Expose() url = '';
    @Expose() id = 0;
    @Expose() filename = '';
    @Expose() lineNr = 0;
    @Expose() kundenNr = 0;
    @Expose() vdzNr: number | null = null;
    @Expose() vcaID: number | null = null;
    @Expose() titel = '';
    @Expose() jahr = 0;
    @Expose() heft = 0;
    @Expose() menge = 0;
    @Expose() kommentar = '';
    @Expose() buchungslogID: number | null = null;

    public get objekt(): number {
        if (this.vdzNr !== null) return this.vdzNr
        if (this.vcaID !== null) return this.vcaID
        return 0
    }

    public get fromErrorfile(): boolean {
        // console.log(this, (this.vdzNr !== null))
        return (this.vdzNr !== null)
    }

    // === === === === === === === === === === === === === === ===

    static fetch = async (): Promise<VCAImportFehlerModel[]> => {

        catApi.debug("fetch ... querying: ");

        try {
            const path = `v1/importe/vca-fehler/`
            const response = await api.get<VCAImportFehlerModel[]>(path);
            catApi.debug(`response: /${path} -> ${JSON.stringify(response)}`);

            const ret = BaseType.convertArray(VCAImportFehlerModel, response.data)

            if (!Array.isArray(ret)) {
                catVue.error("Should have gotten a List, got nothing!", null)
                throw new Error("VCAImportFehlerModel no list")
            }

            return ret;
            //
        } catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }


    patch = async (action: string): Promise<VCAImportFehlerModel | null> => {

        catApi.debug(this.url + " ... patch: " + action);
        try {
            const options = { params: { 'action': action } }
            const path = `v1${this.url}`
            const response = await api.patch<VCAImportFehlerModel[]>(path, undefined, options);
            catApi.debug(`response: ${path} -> ${JSON.stringify(response)}`);

            return BaseType.convert(VCAImportFehlerModel, response.data)
        }
        catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }
}
