







































import { Component, Vue } from "vue-property-decorator";

import { VCAImportFehlerModel } from "@/models/VCAImportFehlerModel";
import { catVue } from "@/util/logging";

//import store from "@/store";

// eslint-disable-next-line

@Component({
  components: {
    VcaFehlerListe: () =>
      import(
        /* webpackChunkName: "vca-fehler-liste" */ "@/components/fehlerlisten/VCAFehlerListe.vue"
      ),
  },
})
export default class VCAFehler extends Vue {
  //

  value = 0;

  loading = true;

  liste = [] as VCAImportFehlerModel[];

  created(): void {
    catVue.info(`${this.$options.name}:: CREATED ... ${this.value}`);
  }

  mounted(): void {
    catVue.info(`${this.$options.name}:: MOUNTED ... ${this.value}`);
    VCAImportFehlerModel.fetch()
      .then((liste) => {
        this.liste = liste;
        this.loading = false;
      })
      .catch((error) => {
        catVue.error(`${this.$options.name}:: ERROR ... ${this.value}`, error);
      });
  }
}

Vue.component("vca-fehler", VCAFehler);
//  store.commit("changeAppLoadingState", true, {root: true});
//  window.setTimeout( () =>   store.commit("changeAppLoadingState", false, {root: true}), 10000)
